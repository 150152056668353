/**
 * 比赛相关API
 */
import { pr } from '@/utils/preview'
import request from '@/utils/request'
// 获取最新比赛ID
export function fetchCurrentContest() {
  return request({
    url: '/contest/latest/id',
    method: 'get'
  })
}

export function retrieveContestInProgress() {
  return request({
    url: '/contest/inProgress',
    method: 'get'
  })
}

export function fetchContestInfo(contestId) {
  return request({
    url: `/contest/${contestId}`,
    method: 'get'
  })
}

export function retrieveContestCategory(contestId) {
  return request({
    url: `/contest/category/${contestId}`,
    method: 'get'
  })
}
export function registration(form, userName) {
  if (form.groupMember instanceof Array) {
    form.groupMember = form.groupMember.join(' ')
  }
  if (form.teacherName instanceof Array) {
    form.teacherName = form.teacherName.join(' ')
  }
  return request({
    url: `/work/registration/${userName}`,
    data: form,
    method: 'post'
  })
}
export function retrieveWork(workId) {
  return request({
    url: `/work/info/${workId}`,
    method: 'get'
  })
}
export function preview(filename) {
  return request({
    // `${process.env.VUE_APP_PIC_URL}/${filename}`,
    url: pr.previewOrigin(filename),
    method: 'GET'
  })
}
export function isExist(fileName) {
  return request({
    url: '/file/isExist',
    params: { fileName },
    method: 'GET'
  })
}
// 根据用户名获取我的赛事
export function fetchMyContestList(username) {
  return request({
    url: `/contest/my/${username}`,
    method: 'get'
  })
}
// 获得用户的报名信息
export function retrieveUserEnrollInfo(userName, contestId) {
  return request({
    url: `/work/enrollInfo/${userName}/${contestId}`,
    method: 'get'
  })
}
