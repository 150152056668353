import request from '@/utils/request'

// 展板上传URL
export const uploadPicUrl = (userName) => {
  return `${process.env.VUE_APP_BASE_API}/work/upload?userName=${userName}&name=poster`
}
// 作品视频上传URL
export const uploadVideoUrl = (userName) => {
  return `${process.env.VUE_APP_BASE_API}/work/upload?userName=${userName}&name=video`
}
// 模型上传URL
export function uploadModelUrl(userName) {
  return `${process.env.VUE_APP_BASE_API}/work/upload?userName=${userName}&name=model`
}

export function deleteWork(workId) {
  return request({
    url: `/work/${workId}`,
    method: 'delete'
  })
}
export function retrieveRegistrationInfo(id) {
  return request({
    url: `/work/${id}`,
    method: 'get'
  }).then(res => {
    console.log('res', res)
    if (res.data.groupMember === '') {
      res.data.groupMember = []
    } else {
      res.data.groupMember = res.data.groupMember.split(' ')
    }
    if (res.data.teacherName === '') {
      res.data.teacherName = []
    } else {
      res.data.teacherName = res.data.teacherName.split(' ')
    }
    return res
  })
}
export function updateRefistrationInfo(form) {
  const formData = Object.assign({}, form)
  if (formData.groupMember instanceof Array) {
    formData.groupMember = formData.groupMember.join(' ')
  }
  if (formData.teacherName instanceof Array) {
    formData.teacherName = formData.teacherName.join(' ')
  }
  return request({
    url: '/work',
    data: formData,
    method: 'put'
  })
}
